<template>
  <div>

    <header-slot />

    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: $route.meta.listEmployeesRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >EMPLOYEES</b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.listDepartmentRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >DEPARTMENTS</b-nav-item>
      <!-- <b-nav-item

          exact
          exact-active-class="active"
          :link-classes="['px-3',bgTabsNavs]"
      >REPORTS</b-nav-item> -->
      <b-nav-item
        :to="{ name: $route.meta.PendingPensionFund }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >PENDING PENSION FUND</b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view />

    </b-card>

  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
</style>
